import z from "zod";

export const GO_AWAY_SENTINEL = JSON.stringify({ type: "goAway" });
export const SLOW_DOWN_SENTINEL = JSON.stringify({ type: "slowDown" });

// Define the message type
export type MessageType = {
  type: "message";
  content: string;
  pId: string;
  timestamp?: Date;
  avatar?: string;
  earnedFire?: number;
};

// Define the message update type
export type MessageUpdateType = {
  type: "update";
  messages: MessageType[];
};

// Define the Zod schema for the message type
const MessageSchema = z.object({
  type: z.literal("message"),
  content: z.string(),
  pId: z.string(),
  timestamp: z.date().optional(),
  avatar: z.string().optional(),
  earnedFire: z.number().optional(),
});

// Create a message object
export const createMessage = (
  content: string,
  pId: string,
  avatar?: string,
  earnedFire?: number
): string => {
  return JSON.stringify(
    MessageSchema.parse({
      type: "message",
      content,
      pId,
      avatar,
      earnedFire,
    })
  );
};

// Parse incoming message
export const parseMessage = (message: string): MessageType => {
  const parsed = JSON.parse(message);
  return MessageSchema.parse({
    ...parsed,
    timestamp: parsed.timestamp ? new Date(parsed.timestamp) : undefined,
  });
};

// Parse update message
export const parseUpdateMessage = (message: string): MessageUpdateType => {
  const parsed = JSON.parse(message);
  return z
    .object({
      type: z.literal("update"),
      messages: z.array(MessageSchema),
    })
    .parse({
      ...parsed,
      messages: parsed.messages.map((msg: any) => ({
        ...msg,
        timestamp: new Date(msg.timestamp),
      })),
    });
};

// Create update message
export const createUpdateMessage = (messages: MessageType[]): string => {
  const structuredMessages = messages.map(
    ({ content, pId, timestamp, avatar, earnedFire }) =>
      MessageSchema.parse({
        type: "message",
        content,
        pId,
        timestamp,
        avatar,
        earnedFire,
      })
  );

  return JSON.stringify({
    type: "update",
    messages: structuredMessages,
  });
};
